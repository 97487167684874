import React from 'react';
import { Card, Button, Row, Col, Badge } from 'react-bootstrap';
import Layout from './Layout';

const AccountStatus = () => {
  return (
   <div className=''>
    <Card className='border'>
      <Card.Header>
        <h5>Account Status</h5>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={6}>
            <div>
              <strong>Account Status:</strong>
              <Badge variant="warning" style={{ marginLeft: '10px' }}>
                Activation Pending
              </Badge>
              <span className="ml-2" style={{ color: '#999' }}>
                <i className="fas fa-hourglass-half"></i> (1 hour)
              </span>
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <div>
              <strong>KYC Status:</strong>
              <span style={{ marginLeft: '10px', color: 'red' }}>
                <i className="fas fa-times-circle"></i> Pending
              </span>
              <Button
                variant="link"
                onClick={() => window.open('https://camskra.com/', '_blank')}
                style={{ color: '#007bff', textDecoration: 'underline' }}
              >
                Check KYC Status
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <div>
              <strong>Personal Details:</strong> <span>Pending</span>
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <div>
              <strong>Bank Details:</strong> <span>Pending</span>
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <div>
              <strong>IIN Activation:</strong> <span>Pending</span>
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <div>
              <strong>Cancelled Cheque Upload:</strong> <span>Pending</span>
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <div>
              <strong>FATCA Declaration:</strong> <span>Pending</span>
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md={6}>
            <div>
              <strong>Risk Profile:</strong> <span>Pending</span>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
    </div>
   
  );
};

export default AccountStatus;

import React from 'react';
import { Card, Button, Row, Col, Form } from 'react-bootstrap';

const BankDetails = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
    console.log("Form Submitted");
  };

  return (
    <div className=''>
    <Card className='border'>
      <Card.Header>
        <h5>Bank Details</h5>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          {/* Bank Name and A/C Holder Name */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Bank Name<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter bank name" required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  A/C Holder Name<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter account holder name" required />
              </Form.Group>
            </Col>
          </Row>

          {/* IFSC and Branch Name */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  IFSC Code<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter IFSC code" required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Branch Name<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter branch name" required />
              </Form.Group>
            </Col>
          </Row>

          {/* A/C Number and Confirm A/C Number */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  A/C Number<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter account number" required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Confirm A/C Number<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Confirm account number" required />
              </Form.Group>
            </Col>
          </Row>

          {/* A/C Type */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  A/C Type<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control as="select" required>
                  <option value="">Select account type</option>
                  <option value="savings">Savings</option>
                  <option value="current">Current</option>
                  <option value="salary">Salary</option>
                  <option value="fixed_deposit">Fixed Deposit</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          {/* Branch Address */}
          <Row className="mb-3">
            <Col md={12}>
              <Form.Group>
                <Form.Label>
                  Branch Address<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter branch address" required />
              </Form.Group>
            </Col>
          </Row>

          {/* Submit Button */}
          <Row>
            <Col md={12}>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
    </div>
  );
};

export default BankDetails;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import Layout from './Layout';
import '../css/PortfolioPage.css';
import { Link } from 'react-router-dom'; // Import Link for navigation

const PortfolioPage = () => {
  const [folioData, setFolioData] = useState(null); 
  const [totalInvestment, setTotalInvestment] = useState(null);
  const [totalCurrentAmount, setTotalCurrentAmount] = useState(null);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const api_Path = process.env.REACT_APP_API_PATH;
        const url = `${api_Path}Portfolio/getFolioData.php`; 
        console.log('Api path =', url);
       
        const response = await axios.get(url, { withCredentials: true });
       
        if (response.data.Success) {
          console.log('Response in portfolio=', response);
          setFolioData(response.data.statusData);
          setTotalInvestment(response.data.totalInvAmount);
          setTotalCurrentAmount(response.data.totalCurAmount);
        } else {
          setError(response.data.Message);
          console.log('Fails', response);
        }
      } catch (error) {
        setError('An error occurred while fetching the data.');
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  
  

  const pnl = totalCurrentAmount - totalInvestment;
  console.log('Pnl'+pnl);
  console.log('totalCurrentAmount' + totalCurrentAmount);
  console.log('totalInvestment'+totalInvestment);

  const pnlPercentage = ((pnl * 100) / totalInvestment) || 0;
  console.log('pnlpercent'+pnlPercentage);

  const pnlStyle = pnl >= 0 ? { color: 'green' } : { color: 'red' };

  return (
    <Layout>
    <Container className="mt-5">
    <h3>Total Investments:</h3>
  {loading && (
    <div className="text-center">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  )}

  {error && (
    <Alert variant="danger" className="text-center">
      {error}
    </Alert>
  )}

  {!loading && !error && folioData && (
    <>
      <Row className="mb-4">
        <Col xs={12}>
          <Card className="border">
            <Card.Body>
              {/* P&L, Invested, Current in a single row */}
              <Row className="text-center">
                <Col>
                <h6 className="text-muted"><strong>P&L</strong></h6>
<p style={pnlStyle} className="mt-2">
  <strong>
    ₹ {pnl >= 0 ? `+${pnl.toFixed(2)}` : pnl.toFixed(2)} <br />
    {pnlPercentage >= 0 ? `+${pnlPercentage.toFixed(2)}%` : `${pnlPercentage.toFixed(2)}%`}
  </strong>
</p>

                </Col>
                <Col>
                  <h6 className="text-muted"><strong>XIRR</strong></h6>
                  <div className="d-flex justify-content-center mt-2">
                    <Button id="btn" className="custom-aqua-button">
                      View 
                    </Button>
                  </div>
                </Col>
                
              </Row>

              <hr />

              {/* XIRR Section */}
              <Row className="text-center">
              <Col>
                  <h6 className="text-muted"><strong>Invested</strong></h6>
                  <p className="mt-2"><strong>₹ {Number(totalInvestment || 0).toFixed(2)}</strong></p>
                </Col>
                <Col>
                  <h6 className="text-muted"><strong>Current</strong></h6>
                  <p className="mt-2"><strong>₹ {Number(totalCurrentAmount || 0).toFixed(2)}</strong></p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )}
</Container>



      <Container>
  <h3>Folio Data:</h3>
  {folioData && folioData.length > 0 ? (
    <Row>
      {folioData
        .filter((item) => item.Currentvalue > 0) // Filter items with Currentvalue > 0
        .map((item, index) => (
          <Col key={index} sm={12} md={12} lg={12} className="mb-4"> {/* Adjusted grid columns */}
            <Card className="border">
              <Card.Body>
                {/* Wrap SchemeName with Link, remove underline */}
                <Card.Title>
                  <h5>
                    <Link
                      to={`/scheme/${item.schemeId}`}
                      state={{ item }}
                      style={{ textDecoration: 'none', color: 'inherit' }} // No underline and inherit text color
                    >
                      {item.SchemeName}
                    </Link>
                  </h5>
                </Card.Title>
                <Card.Subtitle className="mb-3 text-muted">
                  <h6>{item.FundType}</h6>
                </Card.Subtitle>

                {/* P&L, Invested, Current all in single row */}
                <Row className="text-center"> {/* Centering text */}
                  <Col>
                  <h6 className="text-muted"><strong>P&L</strong></h6>
<p
  style={{ color: item.PL >= 0 ? 'green' : 'red' }}
  className="mt-2"
>
  <strong>
    ₹ {item.PL >= 0 ? `+${Number(item.PL).toFixed(2)}` : Number(item.PL).toFixed(2)} <br />
    {item.Percentage >= 0 ? `+${Number(item.Percentage).toFixed(2)}%` : `${Number(item.Percentage).toFixed(2)}%`}
  </strong>
</p>

                  </Col>
                  <Col>
                    <h6 className="text-muted"><strong>Invested</strong></h6>
                    <p className="mt-2"><strong>₹ {Number(item.amount).toFixed(2)}</strong></p>
                  </Col>
                  <Col>
                    <h6 className="text-muted"><strong>Current</strong></h6>
                    <p className="mt-2"><strong>₹ {Number(item.Currentvalue).toFixed(2)}</strong></p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        ))}
    </Row>
  ) : (
    <p>No folio data available.</p>
  )}
</Container>

    </Layout>
  );
};

export default PortfolioPage;

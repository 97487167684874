import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import Layout from './Layout';
import '../css/HomePage.css';
// import '../css/PortfolioPage.css';

const RecommendedPortfolio = () => {
  // Hardcoded portfolio data (can be replaced with API data in the future)
  const [portfolioData, setPortfolioData] = useState([
    {
      scheme: 'HDFC Capital Builder Value Fund - Regular Plan - Growth',
      rationale: 'Growth & Value',
    },
    {
      scheme: 'Quant Small Cap Fund - Regular Growth Plan-Growth',
      rationale: 'High Yield - Aggressive',
    },
    {
      scheme: 'ICICI Prudential India Opportunities Fund Growth',
      rationale: 'Growth & Value',
    },
    {
        scheme:'Aditya Birla Sun Life PSU Equity Fund Regular-Growth',
        rationale :'PSU-Growth & Value',
    }
  ]);

  return (
    <Layout>
      <div className="border portfolio-table-container">
        <h3 className="text-center m-4">Recommended Portfolio</h3>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Scheme</th>
              <th>Rationale</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {portfolioData.map((portfolio, index) => (
              <tr key={index}>
                <td>{portfolio.scheme}</td>
                <td>{portfolio.rationale}</td>
                <td>
                  <Button variant="primary" className="me-2">Buy</Button>
                  <Button variant="success">SIP</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Layout>
  );
};

export default RecommendedPortfolio;

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Table, Button, Spinner, Alert } from 'react-bootstrap';
// import Layout from './Layout';
// import '../css/HomePage.css';

// const RecommendedPortfolio = () => {
//   const [portfolioData, setPortfolioData] = useState([]); // Store portfolio data
//   const [loading, setLoading] = useState(true); // Track loading state
//   const [error, setError] = useState(null); // Track error state

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const api_Path = process.env.REACT_APP_API_PATH; // Base API path from environment variables

//         // Fetch recommended portfolio data
//         const response = await axios.get(`${api_Path}getRecomodationData.php`, {
//           withCredentials: true, // Include cookies for authentication
//         });

//         console.log('API Response:', response.data); // Log response to debug

//         if (response.data.Success && Array.isArray(response.data.Data)) {
//           setPortfolioData(response.data.Data); // Set data to state
//         } else {
//           setError(response.data.Message || 'No portfolio data available.');
//         }
//       } catch (err) {
//         console.error('Error fetching portfolio data:', err);
//         setError('An error occurred while fetching the data.');
//       } finally {
//         setLoading(false); // Stop loading
//       }
//     };

//     fetchData();
//   }, []); // Empty dependency array to run only once on component mount

//   return (
//     <Layout>
//       <div className="border portfolio-table-container">
//         <h3 className="text-center m-4">Recommended Portfolio</h3>

//         {/* Loading Spinner */}
//         {loading && (
//           <div className="text-center my-4">
//             <Spinner animation="border" role="status">
//               <span className="sr-only">Loading...</span>
//             </Spinner>
//           </div>
//         )}

//         {/* Error Message */}
//         {error && (
//           <Alert variant="danger" className="text-center">
//             {error}
//           </Alert>
//         )}

//         {/* Portfolio Table */}
//         {!loading && !error && (
//           <Table striped bordered hover responsive>
//             <thead>
//               <tr>
//                 <th>Scheme</th>
//                 <th>Rationale</th>
//                 <th>Options</th>
//               </tr>
//             </thead>
//             <tbody>
//               {portfolioData.length > 0 ? (
//                 portfolioData.map((portfolio, index) => (
//                   <tr key={index}>
//                     <td>{portfolio.SchemeName}</td> {/* Adjust to API response key */}
//                     <td>{portfolio.rational}</td> {/* Adjust to API response key */}
//                     <td>
//                       <Button variant="primary" className="me-2">
//                         Buy
//                       </Button>
//                       <Button variant="success">SIP</Button>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="3" className="text-center">
//                     No portfolio data available.
//                   </td>
//                 </tr>
//               )}
//             </tbody>
//           </Table>
//         )}
//       </div>
//     </Layout>
//   );
// };

// export default RecommendedPortfolio;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Layout from './Layout';
import { useLocation, useNavigate } from 'react-router-dom';

const RedeemPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { item } = location.state || {}; // Data passed via state

  const [unitsToRedeem, setUnitsToRedeem] = useState('');
  const [redeemAll, setRedeemAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  

  useEffect(() => {
    if (item) {
      setUnitsToRedeem(item.unitAllocated || '');
    }
  }, [item]);

  const handleRedeem = async () => {
    if (!redeemAll && (!unitsToRedeem || isNaN(unitsToRedeem) || unitsToRedeem <= 0)) {
      alert("Please enter a valid number of units to redeem.");
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const api_path = process.env.REACT_APP_API_PATH;
      const response = await fetch(`${api_path}Redeem/redeem_fund.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Folio: item.folioNumber,
          SchemeName: item.SchemeName,
          ProductCode: item.productCode,
          Units: redeemAll ? item.unitAllocated : unitsToRedeem,
          AllUnit: redeemAll ? '1' : '0',
          amc: item.amcCode,
        }),
      });
  
      const rawResponse = await response.text(); // Get the raw response as text
      console.log('Raw Response:', rawResponse);
  
      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.statusText}`);
      }
  
      const result = JSON.parse(rawResponse); // Parse JSON only if it's valid
      if (result.Success) {
        alert(result.Message);
      } else {
        alert(result.Message);
      }
    } catch (err) {
      console.error('Error while redeeming units:', err);
      setError('Failed to redeem units. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  

  if (!item) {
    return <p>No scheme data available.</p>;
  }

  return (
    <Layout>
      <Container className="mt-5">
        <Row>
          <Col md={12}>
            <Card className="border mb-4">
              <Card.Body>
                <h5>{item.SchemeName}</h5>
                <h6 className="text-muted">Units in Portfolio: {item.unitAllocated}</h6>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Card className="border mb-4">
              <Card.Body>
                <Form>
                  <Form.Group controlId="redeemUnits">
                    <Form.Label><strong>Number of Units to Redeem</strong></Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter units"
                      value={redeemAll ? '' : unitsToRedeem}
                      onChange={(e) => setUnitsToRedeem(e.target.value)}
                      disabled={redeemAll || loading}
                    />
                  </Form.Group>
                  <Form.Check
                    type="checkbox"
                    label="Redeem All Units"
                    checked={redeemAll}
                    onChange={() => setRedeemAll(!redeemAll)}
                    disabled={loading}
                  />
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="text-center">
            {error && <p className="text-danger">{error}</p>}
            <Button
              id="btn"
              onClick={handleRedeem}
              className="custom-aqua-button"
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Redeem'}
            </Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default RedeemPage;

import React, { useState } from 'react';
import { Card, Button, Row, Col, Form, Badge } from 'react-bootstrap';

const PersonalDetails = () => {
  const [isNomineeMinor, setIsNomineeMinor] = useState(false);

  const handleNomineeMinorChange = (e) => {
    setIsNomineeMinor(e.target.checked);
  };

  return (
    <div className=''>
    <Card className='border'>
      <Card.Header>
        <h5>Personal Details</h5>
      </Card.Header>
      <Card.Body>
        <Form>
          {/* Full Name */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Full Name (As in PAN card)<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter full name" required />
              </Form.Group>
            </Col>

            {/* Gender */}
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Gender<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control as="select" required>
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          {/* DOB and Marital Status */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Date of Birth (DOB)<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control type="date" required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Marital Status<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control as="select" required>
                  <option value="">Select Marital Status</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="other">Other</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          {/* PAN No. and PIN Code */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  PAN No.<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter PAN number" required />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  PIN Code<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter PIN code" required />
              </Form.Group>
            </Col>
          </Row>

          {/* Country, State, and City */}
          <Row className="mb-3">
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  Country<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter country" required />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  State<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter state" required />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label>
                  City<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter city" required />
              </Form.Group>
            </Col>
          </Row>

          {/* House/Flat No. */}
          <Row className="mb-3">
            <Col md={12}>
              <Form.Group>
                <Form.Label>
                  House/Flat No. and Street Name<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter address" required />
              </Form.Group>
            </Col>
          </Row>

          {/* Nominee Details */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Nominee Name</Form.Label>
                <Form.Control type="text" placeholder="Enter nominee name" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Label>Nominee DOB</Form.Label>
                <Form.Control type="date" />
              </Form.Group>
            </Col>
          </Row>

          {/* Nominee Relation and Is Nominee Minor */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>Nominee Relation</Form.Label>
                <Form.Control type="text" placeholder="Enter relation" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Is nominee minor?"
                  checked={isNomineeMinor}
                  onChange={handleNomineeMinorChange}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* Guardian's Name */}
          {isNomineeMinor && (
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label>Guardian's Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter guardian's name" />
                </Form.Group>
              </Col>
            </Row>
          )}

          {/* Submit Button */}
          <Row>
            <Col md={12}>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
    </div>
  );
};

export default PersonalDetails;

import React, { useState } from 'react';
import { Card, Button, Form, Row, Col, Alert } from 'react-bootstrap';

const RiskProfile = () => {
  const [riskAppetite, setRiskAppetite] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!riskAppetite) {
      alert("Please select a risk appetite before submitting.");
      return;
    }
    setSubmitted(true);
  };

  return (
    <div className=''>
    <Card className='border'>
      <Card.Header>
        <h5>Risk Profile</h5>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={12}>
              <Form.Group>
                <Form.Label>
                  What is your Risk Appetite over 3 years time horizon?<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <div>
                  <Form.Check
                    type="radio"
                    label="30% Negative to capital invested"
                    name="riskAppetite"
                    value="30% Negative"
                    onChange={(e) => setRiskAppetite(e.target.value)}
                    disabled={submitted}
                  />
                  <Form.Check
                    type="radio"
                    label="10% Negative to capital invested"
                    name="riskAppetite"
                    value="10% Negative"
                    onChange={(e) => setRiskAppetite(e.target.value)}
                    disabled={submitted}
                  />
                  <Form.Check
                    type="radio"
                    label="Not expecting capital erosion"
                    name="riskAppetite"
                    value="No Erosion"
                    onChange={(e) => setRiskAppetite(e.target.value)}
                    disabled={submitted}
                  />
                  <Form.Check
                    type="radio"
                    label="Minimum FD returns expected"
                    name="riskAppetite"
                    value="Minimum FD"
                    onChange={(e) => setRiskAppetite(e.target.value)}
                    disabled={submitted}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          {/* Disclaimer */}
          <Row className="mb-3">
            <Col md={12}>
              <Alert variant="info">
                Portfolio Recommended will be as per your Risk Profile. Once selected, you cannot change.
              </Alert>
            </Col>
          </Row>

          {/* Submit Button */}
          <Row>
            <Col md={12}>
              {!submitted ? (
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              ) : (
                <Alert variant="success">
                  Risk profile submitted: <strong>{riskAppetite}</strong>
                </Alert>
              )}
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
    </div>
  );
};

export default RiskProfile;

import React, { useState } from 'react';
import { Card, Form, Button, Row, Col, Alert } from 'react-bootstrap';

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Reset error and success
    setError('');
    setSuccess(false);

    // Validation
    if (!oldPassword || !newPassword || !confirmPassword) {
      setError('All fields are required.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('New Password and Confirm New Password do not match.');
      return;
    }

    if (newPassword === oldPassword) {
      setError('New Password cannot be the same as the Old Password.');
      return;
    }

    // Simulate success (replace with API call)
    setSuccess(true);
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  return (
    <div className=''>
    <Card className='border'>
      <Card.Header>
        <h5>Change Password</h5>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={12}>
              <Form.Group>
                <Form.Label>Old Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter old password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Group>
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Group>
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>

          {error && (
            <Row className="mb-3">
              <Col md={12}>
                <Alert variant="danger">{error}</Alert>
              </Col>
            </Row>
          )}

          {success && (
            <Row className="mb-3">
              <Col md={12}>
                <Alert variant="success">Password changed successfully!</Alert>
              </Col>
            </Row>
          )}

          <Row>
            <Col md={12}>
              <Button variant="primary" type="submit">
                Change Password
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
    </div>
  );
};

export default ChangePassword;

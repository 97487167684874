import React, { useState } from 'react';
import { Card, Button, Row, Col, Form } from 'react-bootstrap';

const UploadForm = () => {
  const [documentType, setDocumentType] = useState('');
  const [image, setImage] = useState(null);

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
    console.log("Document Type:", documentType);
    console.log("Selected File:", image);
  };

  return (
    <div className=''>
    <Card className='border'>
      <Card.Header>
        <h5>Upload Form</h5>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          {/* Document Type */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Document Type<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  value={documentType}
                  onChange={(e) => setDocumentType(e.target.value)}
                  required
                >
                  <option value="">Select document type</option>
                  <option value="pan_card">PAN Card</option>
                  <option value="aadhaar_card">Aadhaar Card</option>
                  <option value="passport">Passport</option>
                  <option value="driving_license">Driving License</option>
                  <option value="voter_id">Voter ID</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          {/* Image Upload */}
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>
                  Upload Image<span style={{ color: 'red' }}>*</span>
                </Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  required
                />
                {image && (
                  <small className="text-muted mt-2 d-block">
                    Selected File: {image.name}
                  </small>
                )}
              </Form.Group>
            </Col>
          </Row>

          {/* Submit Button */}
          <Row>
            <Col md={12}>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
    </div>
  );
};

export default UploadForm;

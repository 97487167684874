// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;


// src/App.js

// import React from 'react';

// import 'bootstrap/dist/css/bootstrap.min.css';

// import HomePage from './components/HomePage';

// function App() {
//   return (
//     <div className="App">
//       <HomePage />
//     </div>
//   );
// }
// export default App;

// src/App.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HomePage from './components/HomePage';
import SignUpPage from './components/SignUpPage';
import LoginPage from './components/LoginPage';
import PortfolioPage from './components/PortfolioPage ';
import LogoutPage from './components/LogoutPage';
import SIPTable from './components/SIPTable';
import RecommendedPortfolio from './components/RecommendedPortfolio ';
import RecommendedTaxSaverFunds from './components/RecommendedTaxSaverFunds ';
import InvestNow from './components/InvestNow ';

import SchemeDetailsPage from './components/SchemeDetailsPage ';
import BuyMorePage from './components/BuyMorePage ';
import RedeemPage from './components/RedeemPage ';
import Profile from './components/Profile';
import AccountStatus from './components/AccountStatus';
import PersonalDetails from './components/PersonalDetails';
import BankDetails from './components/BankDetails';
import UploadForm from './components/UploadForm';
import RiskProfile from './components/RiskProfile';
import ChangePassword from './components/ChangePassword';
import Interview from './components/Interview';

// import { AuthProvider } from './pages/authContext';
import { AuthProvider } from './components/authContext';

const App = () => {
  return (
    <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signup" element={<SignUpPage />} />
        
        <Route path="/login" element={<LoginPage />} /> 
        <Route path="/portfolio" element={<PortfolioPage/>}/>
        <Route path="/logout" element={<LogoutPage/>}/>
        
        <Route path="/sips" element={<SIPTable />} /> 
        <Route path="/recommended-funds" element={<RecommendedPortfolio/>}/>
        <Route path="/tax-saver-funds" element={<RecommendedTaxSaverFunds/>} />
        <Route path="/invest-now" element={<InvestNow />} />

        {/* <Route path="/scheme-detail" component={<SchemeDetailsPage/>} /> */}
        <Route path="/scheme/:schemeId" element={<SchemeDetailsPage />} />
        <Route path="/buy-more" element={<BuyMorePage />} />
        <Route path="/redeem" element={<RedeemPage/>} />
        <Route path="/interview" element={<Interview/>}/>
        
        {/* <Route path="/profile" element={<Profile/>} />  
        <Route path="/account-status" element={<AccountStatus/>} />  */}

<Route path="profile" element={<Profile />}>
          {/* Default child route */}
          <Route index element={<PersonalDetails />} />
          <Route path="account-status" element={<AccountStatus />} />
          <Route path="personal-details" element={<PersonalDetails />} />
          <Route path="bank-details" element={<BankDetails />} />
          <Route path="upload-form" element={<UploadForm />} />
          <Route path="risk-profile" element={<RiskProfile />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
       

       
      </Routes>
    </Router>
    </AuthProvider>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import { Row, Col, ListGroup, Card } from 'react-bootstrap';
import { Outlet, NavLink } from 'react-router-dom';
import Layout from './Layout';

const Profile = () => {
  const [email, setEmail] = useState('');
  const [initials, setInitials] = useState('');

  const tabs = [
    { name: 'Account Status', route: 'account-status' },
    { name: 'Personal Details', route: 'personal-details' },
    { name: 'Bank Details', route: 'bank-details' },
    { name: 'Upload Form', route: 'upload-form' },
    { name: 'Risk Profile', route: 'risk-profile' },
    { name: 'Change Password', route: 'change-password' },
  ];

  useEffect(() => {
    const storedEmail = localStorage.getItem('userEmail');
    if (storedEmail) {
      setEmail(storedEmail);
      const namePart = storedEmail.split('@')[0];
      setInitials(namePart.slice(0, 2).toUpperCase());
    }
  }, []);

  return (
    <Layout>
      <div style={{ marginTop: '20px' }} className=" mb-2">
        <Row>
          {/* Left Side Navigation */}
          <Col md={3}>
            <Card className="border">
              <Card.Header>
                {/* Circular Avatar for Initials */}
                <div
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    backgroundColor: '#007bff',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '20px',
                    fontWeight: 'bold',
                    margin: '10px auto',
                  }}
                >
                  {initials}
                </div>
                <strong>{email}</strong>
              </Card.Header>
              <ListGroup variant="flush">
                {tabs.map((tab) => (
                  <ListGroup.Item key={tab.name}>
                    <NavLink
                      to={tab.route}
                      className={({ isActive }) =>
                        isActive
                          ? 'text-white bg-primary'
                          : 'text-dark'
                      }
                      style={{
                        display: 'block',
                        padding: '10px',
                        textDecoration: 'none',
                        borderRadius: '4px',
                      }}
                    >
                      {tab.name}
                    </NavLink>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
          </Col>

          {/* Right Side Content */}
          <Col md={9}>
            {/* <Card className="border"> */}
              <Card.Body>
                {/* Outlet for Nested Routes */}
                <Outlet />
              </Card.Body>
            {/* </Card> */}
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Profile;
